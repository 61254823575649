import { useCallback, useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import LazyHydrate from 'react-lazy-hydration'
import { Button } from '@/atoms/Button'
import { LinkButton } from '@/atoms/Button/LinkButton'
import { InternalLink } from '@/atoms/InternalLink'
import { AsH2, HeadingLG, ParagraphMD, ParagraphXL } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { press } from '@/constants/press'
import { slugs } from '@/constants/slugs'
import { DocumentCard } from '@/molecules/DocumentCard'
import { EmbeddedVideo as Video } from '@/molecules/EmbeddedVideo'
import { PressHeader } from '@/organisms/PressHeader'
import { TeamMemberDisplay } from '@/organisms/TeamMemberDisplay/TeamMemberDisplay'
import { options } from '@/services/ContentfulService'
import { Downloadable, PressRelease, Project, TeamMember } from '@/services/PressService'
import { EmbeddedVideo } from '@/types/codegen-contentful'
import { useSafeTrack } from '@/utils/analytics'

const RELEASES_TO_SHOW = 5

interface PressViewProps {
  brands?: Project[]
  downloadables: Downloadable[]
  pressReleases: PressRelease[]
  project?: Project
  teamMembers: TeamMember[]
  videos?: EmbeddedVideo[]
}

export const PressView: React.FC<PressViewProps> = ({
  brands = [],
  downloadables,
  pressReleases,
  project,
  teamMembers,
  videos,
}) => {
  const [showMore, setShowMore] = useState([...new Array(teamMembers.length)].fill(false))
  const [showPrevious, setShowPrevious] = useState(false)
  const track = useSafeTrack()
  const logo = project?.logo?.[0]
    ? `v${project.logo[0].version}/${project.logo[0].public_id}.webp`
    : '/h_107,q_auto:best/v1637342010/angel-studios/logos/angel_only_white_2x_1.webp'

  const handleHidePrevious = () => {
    setShowPrevious(false)
  }

  const handleShowPrevious = () => {
    setShowPrevious(true)
  }

  const handleShowMore = useCallback(
    (index: number) => {
      const updatedArray = [...showMore]
      updatedArray[index] = true
      setShowMore(updatedArray)
    },
    [showMore, setShowMore],
  )

  const handleShowLess = useCallback(
    (index: number) => {
      const updatedArray = [...showMore]
      updatedArray[index] = false
      setShowMore(updatedArray)
    },
    [showMore, setShowMore],
  )

  const handleDownloadableClick = useCallback(
    (downloadable: Downloadable) => {
      track('Press Downloadable Clicked', {
        project_slug: project?.slug ?? slugs.angelStudios,
        title: downloadable.title,
        type: downloadable.type,
      })
    },
    [project, track],
  )

  return (
    <div className="bg-white pt-16">
      <PressHeader logo={logo} />
      <main className="md:w-7-12 mx-auto flex w-11/12 max-w-5xl flex-col gap-10 py-10">
        <div className="flex flex-col gap-2 rounded-lg border border-gray-200 bg-gray-100 py-10 text-center">
          <LinkButton
            className="w-fit self-center rounded-full px-10 py-2 text-center"
            href={press.requestAnInterviewFormUrl}
            variant="oxide"
            target="_blank"
          >
            <ParagraphMD>Press Inquiries</ParagraphMD>
          </LinkButton>
        </div>
        {project?.pressCustomInfoSection?.json && (
          <section id="about">{documentToReactComponents(project?.pressCustomInfoSection?.json, options)}</section>
        )}
        {project && downloadables?.length > 0 && (
          <div className="flex flex-col gap-10" id="media-kits">
            <HeadingLG as={AsH2} weight="bold">
              Media Kits
            </HeadingLG>
            <div className="flex flex-wrap gap-2">
              {downloadables.map((downloadable) => {
                return downloadable.image && downloadable.href ? (
                  <DocumentCard
                    authenticated={true}
                    href={downloadable.href}
                    image={downloadable.image}
                    key={downloadable.sys.id}
                    onClick={() => handleDownloadableClick(downloadable)}
                    returnTo={paths.press.index}
                    title={downloadable.title}
                    type={downloadable.type}
                    variant="light"
                  />
                ) : null
              })}
            </div>
          </div>
        )}
        {project && Boolean(videos?.length) && (
          <div className="flex flex-col gap-10" id="trailers">
            <HeadingLG as={AsH2} weight="bold">
              Official Videos
            </HeadingLG>
            <div className="flex flex-wrap gap-2">
              {videos?.map((video) => {
                return video.url ? <Video className="w-full" title={video.title as string} url={video.url} /> : null
              })}
            </div>
          </div>
        )}
        {pressReleases?.length > 0 && (
          <div className="flex flex-col gap-10">
            <HeadingLG as={AsH2} weight="bold">
              Press Releases
            </HeadingLG>
            <div className="flex flex-col gap-1">
              {pressReleases.slice(0, showPrevious ? pressReleases.length : RELEASES_TO_SHOW).map((release) => {
                const date = new Date(release.publishDate ?? release.sys.firstPublishedAt) // create a new Date object with the current date and time
                const formattedDate = date.toLocaleDateString('en-US', {
                  month: '2-digit', // display the month as two digits
                  day: '2-digit', // display the day as two digits
                  year: 'numeric', // display the year as four digits
                }) // format the date string
                return (
                  <div key={release.slug} className="flex flex-col">
                    <InternalLink href={`${paths.press.release}/${release.slug}`}>
                      <ParagraphXL color="copper" underline>
                        {release.title}
                      </ParagraphXL>
                    </InternalLink>
                    <ParagraphMD>{formattedDate}</ParagraphMD>
                  </div>
                )
              })}
              {pressReleases.length > RELEASES_TO_SHOW && (
                <>
                  {showPrevious ? (
                    <Button className="mt-2 w-fit" onClick={handleHidePrevious} variant="plain">
                      <ParagraphMD color="gray-800" underline>
                        Hide Previous Press Releases
                      </ParagraphMD>
                    </Button>
                  ) : (
                    <Button className="mt-2 w-fit" onClick={handleShowPrevious} variant="plain">
                      <ParagraphMD color="gray-800" underline>
                        View Previous Press Releases
                      </ParagraphMD>
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {brands.length > 0 && (
          <LazyHydrate whenVisible>
            <div className="flex flex-col gap-10">
              <HeadingLG as={AsH2} weight="bold">
                Brands
              </HeadingLG>
              <div className="flex flex-col gap-1">
                {brands.map((brand) => (
                  <InternalLink key={brand.slug} href={`${paths.press.index}/${brand.slug}`}>
                    <ParagraphXL color="copper" underline>
                      {brand.name}
                    </ParagraphXL>
                  </InternalLink>
                ))}
              </div>
            </div>
          </LazyHydrate>
        )}
        {teamMembers?.length > 0 && (
          <LazyHydrate whenVisible>
            <div className="flex flex-col gap-10">
              <HeadingLG as={AsH2} weight="bold">
                {project ? 'About the Cast and Filmmakers' : 'About Our Team'}
              </HeadingLG>
              <div className="flex flex-col gap-12">
                {teamMembers.map((member, index) => (
                  <TeamMemberDisplay
                    key={member.sys.id}
                    member={member}
                    onShowLess={() => handleShowLess(index)}
                    onShowMore={() => handleShowMore(index)}
                    showMore={showMore[index]}
                  />
                ))}
              </div>
            </div>
          </LazyHydrate>
        )}
        {/* keep this out of dynamic code so pragma links work */}
        <div className="invisible" id="media-kits" />
        {!project && downloadables?.length > 0 && (
          <LazyHydrate whenVisible>
            <div className="flex flex-col gap-10">
              <HeadingLG as={AsH2} weight="bold">
                Media Kits
              </HeadingLG>
              <div className="flex flex-wrap gap-2">
                {downloadables.map((downloadable) => {
                  return downloadable.image && downloadable.href ? (
                    <DocumentCard
                      authenticated={true}
                      href={downloadable.href}
                      image={downloadable.image}
                      key={downloadable.sys.id}
                      onClick={() => handleDownloadableClick(downloadable)}
                      returnTo={paths.press.index}
                      title={downloadable.title}
                      type={downloadable.type}
                      variant="light"
                    />
                  ) : null
                })}
              </div>
            </div>
          </LazyHydrate>
        )}
      </main>
    </div>
  )
}
